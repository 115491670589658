
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import EstimateMemoAddEditModal from "@/modals/estimate/MemoAddEditModal.vue";
import SelectRatingModal from "@/modals/core/SelectRatingModal.vue";
import EstimateSelectTeamModal from "@/modals/estimate/EstimateSelectTeamModal.vue";
import PaymentSendSmsModal from "@/modals/estimate/PaymentSendSmsModal.vue";
import EstimateMemoEditModal from "@/modals/estimate/EstimateMemoEditModal.vue";
import EstimatePaymentComponent from "@/components/estimate/EstimatePaymentComponent.vue";
import EstimatePrintComponent from "@/components/estimate/EstimatePrintComponent.vue";
import UpdateEvent from "@/models";
import TeamService from "@/services/team/team.service";
import { TableOrder, UpdateEventType } from "@/types";
import EstimateService from "@/services/work/estimate.service";
import core from "@/core";
import ReviewService from "@/services/review/review.service";
import { ScheduleViewType } from "@/models/schedule";
import SmsService from "@/services/sms.service";
import MixinsSession from "@/mixins/single/session";
import MixinsViewHintStorage from "@/mixins/single/view-hint-storage";
import { UserModel } from "@/models/user/user.model";
import { Style } from "@/store/modules/top-toolbar";
import EstimateV2PrintComponent from "@/components/estimate/EstimateV2PrintComponent.vue";
import SmsTextAreaModal from "@/modals/core/SmsTextAreaModal.vue";

export default mixins(MixinsPage, MixinsSession, MixinsViewHintStorage).extend({
  name: "EstimateView",
  components: {
    SmsTextAreaModal,
    EstimateV2PrintComponent,
    EstimateMemoAddEditModal,
    SelectRatingModal,
    EstimateSelectTeamModal,
    PaymentSendSmsModal,
    EstimateMemoEditModal,
    EstimatePaymentComponent,
    EstimatePrintComponent,
  },

  data: () => ({
    rating: 4.5,
    sessionItems: {
      type: "estimate-view",
    },
    type: "view",
    title: "",
    fab: null,
    estimateId: 0,
    estimate: null as any,
    scheduleList: [] as any,
    mapLink: "",
    component: {
      payment: {
        params: {
          estimateId: 0,
          estimate: null as any,
          addIconEnable: true,
        },
        updateEvent: null as UpdateEvent | null,
      },
    },
    unScheduledCategoryText: "",
    visible: {
      estimate: false,
      paymentSms: false,
      paymentSmsButton: false,
      scheduleSms: false,
      scheduleSmsButton: false,
      mapMaker: false,
    },
    modal: {
      sms: {
        visible: false,
        params: {
          length: {
            min: 1,
            max: 1000,
          },
          title: "문자",
          text: "",
          item: null as any,
          enableDeleteButton: false,
        },
        updateEvent: null as UpdateEvent | null,
      },
      rating: {
        visible: false,
        selectedItem: null as any,
        params: {
          title: "",
          value: null as any,
        },
        updateEvent: null as UpdateEvent | null,
      },
      memoEdit: {
        visible: false,
        params: {
          estimate: null,
        },
        updateEvent: null as UpdateEvent | null,
      },
      memoAddEdit: {
        visible: false,
        params: {
          type: "add",
          item: null,
        },
        updateEvent: null as UpdateEvent | null,
      },
      paymentSms: {
        visible: false,
        params: {
          estimate: null,
        },
        updateEvent: null as UpdateEvent | null,
      },
      team: {
        visible: false,
        params: {
          categoryId: null as any,
          teamId: null as any,
          list: null as any,
        },
        updateEvent: null as UpdateEvent | null,
      },
    },
    teamList: [] as any,
    memoList: [] as any,
    user: {} as UserModel,
  }),
  created() {
    const params = this.$route.params as any;
    this.estimateId = params.id;
    if (this.estimateId <= 0) {
      this.notFound();
    } else {
      this.component.payment.params.estimateId = this.estimateId;
    }
    if (this.isNotBlank(this.$route.query.type)) {
      this.type = String(this.$route.query.type);
    }
  },
  mounted() {
    this.$nextTick(async () => {
      this.user = (await this.$store.getters["auth/user"]()) as UserModel;

      await this.sessionWait();
      await this.getEstimate();

      const query = this.$route.query;
      const action = query.action;
      if (action === "addSchedule") {
        this.showScheduleAddPage(this.estimate, query.categoryId as string);
      } else {
        await this.checkSendSms();
      }

      await this.viewHintStorageWait();

      this.changedEstimate();

      if (this.type !== "deleted") {
        this.teamList = await this.$store.getters["app/getTeamList"]();
        await this.getMemoList();
      }
    });
  },

  async activated() {
    if (this.type === "deleted" && this.estimate != null && this.estimate.deletedAt == null) {
      this.goBack();
      return;
    }
    const event = this.getPageUpdateEvent();
    if (event != null) {
      if (
        event.result === UpdateEventType.RELOAD ||
        event.result === UpdateEventType.UPDATE ||
        event.result === UpdateEventType.DELETE
      ) {
        await this.getEstimate();
        this.updatePreVmEvent(UpdateEventType.UPDATE, this.estimate);
      } else {
        console.log("unknown result : ", event.result);
      }
    }

    if (this.estimate != null && this.estimate.caution) {
      this.$store.dispatch("topToolbar/changeStyle", {
        dark: true,
        color: "red",
        clazz: "",
      } as Style);
    } else {
      this.$store.dispatch("topToolbar/changeOrgStyle");
    }
    this.checkSendSms();
  },
  watch: {
    "modal.sms.updateEvent"() {
      const modal = this.modal.sms;
      const event = this.getComponentUpdateEvent(modal);
      if (event != null) {
        if (event.result === UpdateEventType.CONFIRM) {
          const item = event.item;
          const estimate = modal.params.item;
          console.log("result item : ", item);
          this.sendScheduleSms(estimate, item);
        } else {
          console.log("unknown event result : ", event);
        }
      }
    },
    "modal.rating.updateEvent"(event) {
      const modal = this.modal.rating;
      //console.log("event : ", event);
      if (event != null) {
        if (event.result == UpdateEventType.CONFIRM) {
          modal.selectedItem.score = event.item;

          const reviewScore = modal.selectedItem;
          this.updateScore(reviewScore);
        }
      }
      modal.selectedItem = null;
    },
    estimate() {
      this.changedEstimate();
    },
    async "component.payment.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.component.payment);
      if (event != null) {
        if (event.result === UpdateEventType.RELOAD) {
          await this.getEstimate();
          this.updatePreVmEvent(UpdateEventType.UPDATE, this.estimate);
        } else {
          console.log("unknown result : ", event.result);
        }
      }
    },
    async "modal.paymentSms.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.paymentSms);
      if (event != null) {
        if (event.result === UpdateEventType.RELOAD) {
          await this.getEstimate();
          this.updatePreVmEvent(UpdateEventType.UPDATE, this.estimate);
        } else {
          console.log("unknown result : ", event.result);
        }
      }
    },
    async "modal.memoEdit.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.memoEdit);
      if (event != null) {
        if (event.result === UpdateEventType.UPDATE) {
          await this.getEstimate();
          this.estimate = event.item;

          this.convertEstimate(this.estimate);
        } else {
          console.log("unknown result : ", event.result);
        }
      }
    },
    "modal.memoAddEdit.updateEvent"() {
      const modal = this.modal.memoAddEdit;
      const event = this.getComponentUpdateEvent(modal);
      if (event != null) {
        if (event.result === UpdateEventType.CONFIRM) {
          const item = event.item;
          if (modal.params.type === "add") {
            // console.log("add memo : ", item);
            this.createMemo(item);
          } else if (modal.params.type === "edit") {
            this.updateMemo(modal.params.item, item);
          } else {
            console.log("unknown type : ", item);
          }
        } else if (event.result === UpdateEventType.DELETE) {
          const item = modal.params.item;
          console.log("delete memo : ", item);
          this.deleteMemo(item);
        } else {
          console.log("unknown result : ", event.result);
        }
      }
    },
    async "modal.team.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.team);
      if (event != null) {
        if (event.result === UpdateEventType.CONFIRM) {
          if (event.item != null) {
            console.log("selectd category team : ", event.item);
            // const categoryId = event.item.categoryId;
            // const teamId = event.item.teamId;

            try {
              this.estimate = await EstimateService.updateCategoryTeam(
                this.estimate.id,
                event.item
              );
              this.changedEstimate();
            } catch (e) {
              console.log(e);
            }
          }
        } else {
          console.log("unknown result : ", event.result);
        }
      }
    },
  },
  methods: {
    getMemoUser(memo) {
      if (memo.updatedUser && memo.createdUser) {
        if (memo.updatedUser.id !== memo.createdUser.id) {
          return `, ${memo.createdUser.name}, ${memo.updatedUser.name} 수정`;
        } else {
          return `, ${memo.createdUser.name}`;
        }
      }
      if (memo.createdUser) {
        return `, ${memo.createdUser.name}`;
      }
    },
    textToHtml(memo) {
      return core.utils.format.textToHtml(memo);
    },
    addMemo() {
      const estimate = this.estimate;
      const modal = this.modal.memoAddEdit;
      modal.params.type = "add";

      modal.visible = true;
    },
    async createMemo(params) {
      try {
        console.log("params : ", params);
        const estimate = this.estimate;
        const memo = await EstimateService.createMemoInfo(estimate.id, params);
        console.log("memo : ", memo);
        this.getMemoList();
      } catch (e) {
        console.log(e);
      }
    },
    editMemo(item) {
      console.log("memo : ", item);
      const estimate = this.estimate;
      const modal = this.modal.memoAddEdit;
      modal.params.type = "edit";
      modal.params.item = item;

      modal.visible = true;
    },
    async updateMemo(item, params) {
      try {
        console.log("item : ", item, "params : ", params);
        const estimate = this.estimate;
        const memo = await EstimateService.updateMemoInfo(estimate.id, item.id, params);
        console.log("memo : ", memo);
        this.getMemoList();
      } catch (e) {
        console.log(e);
      }
    },
    async deleteMemoShowConfirm(item) {
      const result = await core.alert.show({
        title: "확인",
        body: "삭제를 계속 진행하시겠습니까?",
        showCancelButton: true,
        cancelButtonText: "아니오",
        confirmButtonText: "예",
      });
      if (result === "confirm") {
        this.deleteMemo(item);
      }
    },
    async deleteMemo(item) {
      try {
        console.log("item : ", item);
        const estimate = this.estimate;
        const memo = await EstimateService.deleteMemoInfo(estimate.id, item.id);
        console.log("memo : ", memo);
        this.getMemoList();
      } catch (e) {
        console.log(e);
      }
    },
    async getMemoList() {
      // 결제 목록 조회
      try {
        const memoList = (this.memoList = await EstimateService.getMemoList(this.estimateId));
      } catch (e) {
        console.log(e);
      }
    },
    async restore() {
      // 견적서 복구
      console.log("견적서 복구하기");
      core.loader.show();
      try {
        const estimate = (this.estimate = await EstimateService.restore(this.estimateId));
        console.log("estimate : ", estimate);
        this.updatePreVmEvent(UpdateEventType.DELETE, estimate);
        this.$router.push({
          path: "/estimate/" + this.estimateId,
        });
      } catch (e: any) {
        console.log(e);
        core.alert.show({
          title: "알림",
          body: e.message,
        });
      } finally {
        core.loader.hide();
      }
    },
    async updateScore(params) {
      console.log("update score : ", params);

      try {
        const _params = core.utils.deepCopy(params);
        if (_params.score != null) {
          _params.score = _params.score * 10;
        }

        const reviewScore = await ReviewService.updateScore(_params);
        console.log("reviewScore : ", reviewScore);
      } catch (e) {
        console.log(e);
      }
    },
    async showTeamSelectModal(item) {
      const modal = this.modal.team;
      const teamList = this.teamList;

      const list = [] as any;
      list.push({ id: null, name: "없음" });
      teamList.forEach((team) => {
        if (team.category.id == item.category.id) {
          list.push(team);
        }
      });
      // console.log("list : ", list);
      modal.params.list = list;
      modal.params.categoryId = item.category.id;
      if (item.team == null) {
        modal.params.teamId = null;
      } else {
        modal.params.teamId = item.team.id;
      }
      modal.visible = true;
    },
    async updateIssue(issue) {
      const estimate = this.estimate;
      if (estimate == null) {
        return;
      }
      try {
        core.loader.show();
        const _estimate = (await EstimateService.updateIssue(estimate.id, issue)) as any;
        this.estimate = _estimate;
        this.changedEstimate();
        this.updatePreVmEvent(UpdateEventType.RELOAD);

        if (issue) {
          await core.alert.show({
            title: "알림",
            body: "이슈 견적으로 등록되었습니다.",
          });
        } else {
          await core.alert.show({
            title: "알림",
            body: "이슈 견적 등록이 해제되었습니다.",
          });
        }
      } catch (e) {
        console.log(e);
      } finally {
        core.loader.hide();
      }
    },
    async updateCaution(caution) {
      const estimate = this.estimate;
      if (estimate == null) {
        return;
      }
      try {
        core.loader.show();
        const _estimate = (await EstimateService.updateCaution(estimate.id, caution)) as any;
        this.estimate = _estimate;
        this.changedEstimate();
        this.updatePreVmEvent(UpdateEventType.RELOAD);

        if (caution) {
          await core.alert.show({
            title: "알림",
            body: "주의 견적으로 등록되었습니다.",
          });
        } else {
          await core.alert.show({
            title: "알림",
            body: "주의 견적 등록이 해제되었습니다.",
          });
        }
      } catch (e) {
        console.log(e);
      } finally {
        core.loader.hide();
      }
    },
    async updateAutoPaymentSms(autoPaymentSms) {
      const estimate = this.estimate;
      if (estimate == null) {
        return;
      }
      try {
        core.loader.show();
        const _estimate = (await EstimateService.updateAutoPaymentSms(
          estimate.id,
          autoPaymentSms
        )) as any;
        this.estimate = _estimate;
        this.changedEstimate();
        this.updatePreVmEvent(UpdateEventType.RELOAD);

        if (autoPaymentSms) {
          await core.alert.show({
            title: "알림",
            body: "결제문자가 시스템에서 자동으로 전송됩니다",
          });
        } else {
          await core.alert.show({
            title: "알림",
            body: "결제문자를 시스템에서 전송하지 않습니다",
          });
        }
      } catch (e) {
        console.log(e);
      } finally {
        core.loader.hide();
      }
    },
    async changedEstimate() {
      const estimate = this.estimate;
      if (estimate == null) {
        return;
      }

      const visible = this.visible;
      const viewHintItems = this.getViewHintStorageItems();
      if (this.isCompanyManagerRoleHigher) {
        visible.paymentSmsButton = estimate.paymentYn === "N";
        if (visible.paymentSmsButton) {
          if (!viewHintItems.estimatePaymentSms) {
            visible.paymentSms = true;
          }
        } else if (visible.paymentSms) {
          visible.paymentSms = false;
        }
      }

      if (this.isCompanyManagerRoleHigher) {
        this.visible.estimate = true;
        this.visible.scheduleSmsButton = true;
        if (!viewHintItems.estimateScheduleSms) {
          this.visible.scheduleSms = true;
        }
      } else if (
        estimate.createdUser != null &&
        estimate.createdUser.username === this.user.username
      ) {
        this.visible.estimate = true;
        this.visible.scheduleSmsButton = true;
        if (!viewHintItems.estimateScheduleSms) {
          this.visible.scheduleSms = true;
        }
      }

      this.visible.mapMaker = false;
      if (this.app.isMobile && estimate.place != null) {
        const platform = core.utils.platform();
        if (platform === "android") {
          this.visible.mapMaker = true;
          let address = "";
          const place = estimate.place;
          let location = "";
          if (place.latitude != null && place.longitude != null) {
            location = place.latitude + "," + place.longitude;
          }
          if (this.isNotBlank(place.address1)) {
            address = place.address1;
            if (this.isNotBlank(place.address2)) {
              address += " " + place.address2;
            }
          } else {
            address = place.name;
          }
          this.mapLink = "geo:" + location + "?q=" + address;
        }
      }

      if (estimate.scheduleYn === "N" && estimate.detailList != null && this.scheduleList != null) {
        const scheduleList = this.scheduleList;

        const detailMap = {};
        estimate.detailList.forEach((detail) => {
          detailMap[detail.id] = detail;
        });
        scheduleList.forEach((schedule) => {
          if (schedule.detailList != null) {
            schedule.detailList.forEach((scheduleDetail) => {
              delete detailMap[scheduleDetail.estimateDetailId];
            });
          }
        });
        const categoryMap = {};
        for (const key of Object.keys(detailMap)) {
          const detail = detailMap[key];
          categoryMap[detail.category.id] = detail.category;
        }
        let categoryText = "";

        const estimateCategoryList = estimate.categoryList;
        for (const key of Object.keys(categoryMap)) {
          const category = categoryMap[key];
          categoryText += category.name + ",";
        }
        this.unScheduledCategoryText = categoryText.substr(0, categoryText.length - 1);
        // console.log("categoryMap : ", categoryMap);
      }

      if (estimate.workStatus === "C") {
        if (estimate.reviewScoreAt == null) {
          estimate.reviewScoreAt = new Date(0);
        }
        const date = new Date();
        const elapsedTimeMillis = date.getTime() - estimate.reviewScoreAt.getTime();
        if (elapsedTimeMillis >= 1000) {
          // 고객후기 가져오기
          try {
            const reviewScoreList = (await ReviewService.getScoreList(estimate.id)) as any;
            //console.log("reviewScoreList : ", reviewScoreList);

            let list = [] as any;
            estimate.reviewScoreAt = date;
            estimate.categoryList.forEach((categoryItem) => {
              let reviewScore = null as any;
              reviewScoreList.every((_reviewScore) => {
                if (_reviewScore.categoryId == categoryItem.category.id) {
                  reviewScore = _reviewScore;
                  return false;
                } else {
                  return true;
                }
              });
              if (reviewScore == null) {
                reviewScore = {
                  estimateId: estimate.id,
                  categoryId: categoryItem.category.id,
                  score: null,
                  remark: "",
                } as any;
              }
              if (reviewScore.score != null) {
                reviewScore.score = reviewScore.score / 10;
              }
              reviewScore.category = categoryItem.category;
              list.push(reviewScore);
            });
            estimate.reviewScoreList = list;
          } catch (e) {
            console.log(e);
          }
        }
      }
      if (estimate.caution) {
        this.$store.dispatch("topToolbar/changeStyle", {
          dark: true,
          color: "red",
          clazz: "",
        } as Style);
      } else {
        this.$store.dispatch("topToolbar/changeOrgStyle");
      }
    },
    getPriceText() {
      const estimate = this.estimate;
      if (estimate.paymentYn === "Y") {
        return `(총금액 ${this.moneyFormat(estimate.totalPrice)}, 결제 ${this.moneyFormat(
          estimate.payment
        )}, <span class="green--text">결제 완료</span>)`;
      } else {
        const value = String(Number(estimate.totalPrice) - Number(estimate.payment));
        return `(총금액 ${this.moneyFormat(estimate.totalPrice)}, 결제 ${this.moneyFormat(
          estimate.payment
        )}, <span class="blue--text">잔금 ${this.moneyFormat(value)}</span>)`;
      }
    },
    getExpectedDateText() {
      if (this.isBlank(this.estimate.expectedDate)) {
        return "";
      } else {
        return `(입주예정일 ${this.dateFormat(this.estimate.expectedDate)})`;
      }
    },
    async checkSendSms() {
      if (this.estimate != null && this.estimate.customerViewYn === "Y") {
        //console.log("estimate : ", this.estimate);
        const createdAtMoment = core.date.instance(this.estimate.createdAt);
        const diffSec = core.date.instance().diff(createdAtMoment, "seconds");
        if (diffSec < 60) {
          const items = this.getSessionItems();
          const key = "check_sms_estimate_" + this.estimate.id;
          const value = items[key];
          if (value !== this.estimate.createdAt) {
            await this.sendSms(this.estimate);
            items[key] = this.estimate.createdAt;
            this.saveSessionItems();
          }
        }
      }
    },
    showEstimateMemoEditModal() {
      const modal = this.modal.memoEdit;
      modal.params.estimate = this.estimate;
      modal.visible = true;
    },
    showAddAsPage(estimate) {
      // console.log("estimate : ", estimate);
      this.$router.push({
        path: `/schedule/as`,
        query: {
          viewType: ScheduleViewType.ESTIMATE,
          estimateId: estimate.id,
        },
      });
    },
    showCommentPage(estimate) {
      this.$router.push({
        path: `/estimate/${estimate.id}/comment`,
      });
    },
    showSupportPage(estimate) {
      this.$router.push({
        path: `/support/${estimate.id}`,
      });
    },
    showReviewScoreModal(estimate, reviewScore) {
      const modal = this.modal.rating;
      modal.selectedItem = reviewScore;
      modal.params.title = reviewScore.category.name + " 만족도";
      modal.params.value = reviewScore.score;
      modal.visible = true;
    },
    showScheduleAddPage(estimate, categoryId?: string) {
      // console.log("estimate : ", estimate);
      const query = {
        viewType: ScheduleViewType.ESTIMATE,
        estimateId: estimate.id,
      } as any;
      if (categoryId != null) {
        query.categoryId = categoryId;
      }
      this.$router.push({
        path: `/schedule/add`,
        query: query,
      });
    },
    showScheduleEditPage(estimate, schedule) {
      // console.log("schedule : ", schedule);
      this.$router.push({
        path: `/schedule/edit`,
        query: {
          viewType: ScheduleViewType.ESTIMATE,
          id: schedule.id,
        },
      });
    },
    showScheduleViewPage(estimate, schedule) {
      this.$router.push({
        path: `/schedule/${schedule.id}`,
        query: {
          viewType: ScheduleViewType.ESTIMATE,
          estimateId: estimate.id,
        },
      });
    },
    onPrint() {
      this.$router.push({
        path: `/estimate/${this.estimateId}/print`,
      });
    },
    scheduleDateText(item) {
      const startDate = core.date.instance(item.startAt).format("YYYY.MM.DD(ddd)");
      const endDate = core.date.instance(item.endAt).format("YYYY.MM.DD(ddd)");
      const startTime = core.date.instance(item.startAt).format("a hh:mm");
      const endTime = core.date.instance(item.endAt).format("a hh:mm");

      if (item.allDay) {
        if (startDate == endDate) {
          return startDate;
        } else {
          return startDate + " ~ " + endDate;
        }
      } else {
        if (startDate == endDate) {
          return `${startDate} ${startTime} ~ ${endTime}`;
        } else {
          return `${startDate} ${startTime}` + " ~ " + `${endDate} ${endTime}`;
        }
      }
    },
    scheduleTeamText(item) {
      if (item.team != null) {
        return item.team.name + "팀";
      }
      return "";
    },
    scheduleUserText(item) {
      if (item.userList != null && item.userList.length > 0) {
        let text = "";
        item.userList.forEach((user, index) => {
          //console.log("user : ", user);
          if (index > 0) text += ",";
          text += user.name;
        });
        return text;
      }
      return "";
    },
    scheduleText(item) {
      const date = this.scheduleDateText(item);

      let text = "";
      if (item.category != null) {
        text = `${item.category.name} (${date})`;
      } else {
        text = date;
      }

      // let strDateType = "";
      // if (item.type === "D") {
      //   strDateType = "종일";
      // } else if (item.type === "A") {
      //   strDateType = "오전";
      // } else if (item.type === "P") {
      //   strDateType = "오후";
      // }

      if (item.workType === "A") {
        return `A/S ${text}`;
      }
      return text;
    },
    contractStatusText(value) {
      if (value === "WAIT") return "<span class='red--text'>계약 대기</span>";
      else if (value === "COMPLETE") return "<span class='green--text'>계약 완료</span>";
      else if (value === "CANCEL") return "<span class='black--text'>계약 취소</span>";
      else if (value === "SPECIAL_PRICE") return "<span class='orange--text'>특가표</span>";
      else if (value === "TEMPORARY") return "<span class='orange--text'>임시 계약</span>";
      return "알 수 없음";
    },
    updateTitle() {
      if (this.estimate != null) {
        this.title = `${this.estimate.place.name}`;
        if (this.isNotBlank(this.estimate.dong)) {
          this.title += ` ${this.estimate.dong}동`;
        }
        if (this.isNotBlank(this.estimate.ho)) {
          this.title += ` ${this.estimate.ho}호`;
        }
      } else {
        this.title = "";
      }
    },
    addressText(estimate) {
      const place = estimate.place;

      let address = "";
      if (place.address1 != null) {
        if (core.utils.validate.isBlank(place.address2)) {
          address = `${place.address1}`;
        } else {
          address = `${place.address1} ${place.address2}`;
        }
      } else {
        address = place.name;
      }

      if (this.isNotBlank(estimate.dong)) {
        address += ` ${estimate.dong}동`;
      }
      if (this.isNotBlank(estimate.ho)) {
        address += ` ${estimate.ho}호`;
      }

      return address;
    },
    async getEstimate() {
      core.loader.show();
      try {
        let estimate = null as any;
        if (this.type === "deleted") {
          console.log("deleted estimate");
          const tableResponse = (await EstimateService.getTable({
            draw: 0,
            start: 0,
            length: 10,
            orderColumnName: "id",
            order: TableOrder.DESC,
            searchColumns: { id: this.estimateId, deletedAtIsNull: false },
          })) as any;
          if (tableResponse.data.length > 0) {
            estimate = tableResponse.data[0];
          } else {
            await this.notFound();
            return;
          }
          console.log("tableResponse : ", tableResponse);
        } else {
          estimate = (await EstimateService.get(this.estimateId)) as any;
        }
        estimate.reviewScoreAt = null;
        estimate.reviewScoreList = [];

        this.estimate = estimate;
        // console.log("workStatus : ", this.estimate.workStatus);
        this.component.payment.params.estimate = this.estimate;

        this.convertEstimate(this.estimate);

        this.updateTitle();

        this.scheduleList = (await EstimateService.getScheduleList(this.estimateId)) as any;
        this.changedEstimate();
      } catch (e) {
        console.log(e);
        await this.notFound();
      }
      core.loader.hide();
    },

    convertEstimate(estimate: any) {
      this.component.payment.params.addIconEnable = estimate.paymentYn !== "Y";

      if (estimate.contractStatus === "CANCEL" && estimate.payment > 0) {
        this.component.payment.params.addIconEnable = true;
      }
      if (this.isBlank(estimate.memo)) {
        estimate.memoView = "<span class='grey--text'>내용없음</span>";
      } else {
        estimate.memoView = core.utils.format.textToHtml(estimate.memo);
      }
    },

    async updateContractEstimate(estimate, status) {
      const result = await core.alert.show({
        title: "확인",
        body: "계약상태를 변경 하시겠습니까?",
        showCancelButton: true,
        cancelButtonText: "아니오",
        confirmButtonText: "예",
      });
      if (result === "confirm") {
        try {
          await EstimateService.updateContract(estimate.id, status);
          await this.getEstimate();
          this.updatePreVmEvent(UpdateEventType.UPDATE, this.estimate);
        } catch (e) {
          console.log(e);
        }
      }
    },
    async deleteEstimate(estimate) {
      const result = await core.alert.show({
        title: "확인",
        body: "삭제를 계속 진행하시겠습니까?",
        showCancelButton: true,
        cancelButtonText: "아니오",
        confirmButtonText: "예",
      });
      if (result === "confirm") {
        try {
          (await EstimateService.delete(estimate.id)) as any;
          this.goBack(UpdateEventType.DELETE, estimate);
        } catch (e) {
          console.log(e);
        }
      }
    },
    async showEstimateEditPage() {
      this.$router.push({
        path: `/estimate/edit`,
        query: {
          id: String(this.estimateId),
        },
      });
    },
    async sendSms(item) {
      const result = await core.alert.show({
        title: "확인",
        body: `고객님께 견적서 정보를 전송하시겠습니까?`,
        showCancelButton: true,
        cancelButtonText: "아니오",
        confirmButtonText: "예",
      });

      if (result === "confirm") {
        try {
          core.loader.show("문자 전송중...");
          const smsPushId = (await EstimateService.sendSms(item.id, {
            type: "ESTIMATE",
          })) as string;
          const vm = this as any;
          const interval = setInterval(async () => {
            try {
              if (vm._isDestroyed) {
                clearInterval(interval);
              } else {
                const apiResult = (await SmsService.getSmsResult(smsPushId)) as any;
                if (apiResult.result) {
                  clearInterval(interval);
                  core.loader.hide();
                  core.alert.show({
                    title: "알림",
                    body: "문자 전송 완료",
                  });
                  // 문자 전송 시간 갱신
                  await vm.getEstimate();
                  vm.updatePreVmEvent(UpdateEventType.UPDATE, vm.estimate);
                } else if (apiResult.code < 0) {
                  clearInterval(interval);
                  core.loader.hide();
                  core.alert.show({
                    title: "알림",
                    body: apiResult.message,
                  });
                }
              }
            } catch (e) {
              console.log(e);
            }
          }, 2000);
        } catch (e) {
          console.log(e);
        }
      }
    },
    async sendReviewSms(item) {
      const result = await core.alert.show({
        title: "확인",
        body: `고객님께 후기 등록 정보를 전송하시겠습니까?`,
        showCancelButton: true,
        cancelButtonText: "아니오",
        confirmButtonText: "예",
      });

      if (result === "confirm") {
        try {
          core.loader.show("문자 전송중...");
          const smsPushId = (await EstimateService.sendReviewSms(item.id)) as string;
          const vm = this as any;
          const interval = setInterval(async () => {
            try {
              if (vm._isDestroyed) {
                clearInterval(interval);
              } else {
                const apiResult = (await SmsService.getSmsResult(smsPushId)) as any;
                if (apiResult.result) {
                  clearInterval(interval);
                  core.loader.hide();
                  core.alert.show({
                    title: "알림",
                    body: "문자 전송 완료",
                  });
                  // 문자 전송 시간 갱신
                  await vm.getEstimate();
                  vm.updatePreVmEvent(UpdateEventType.UPDATE, vm.estimate);
                } else if (apiResult.code < 0) {
                  clearInterval(interval);
                  core.loader.hide();
                  core.alert.show({
                    title: "알림",
                    body: apiResult.message,
                  });
                }
              }
            } catch (e) {
              console.log(e);
            }
          }, 2000);
        } catch (e) {
          console.log(e);
        }
      }
    },

    async showScheduleSms(estimate) {
      if (this.app.isMobile) {
        this.sendScheduleSms(estimate, null);
      } else {
        const smsScheduleContent = (await EstimateService.getSmsSchedule(estimate.id)) as any;
        const modal = this.modal.sms;
        modal.params.text = smsScheduleContent;
        modal.params.item = estimate;
        modal.visible = true;
      }
    },
    async sendScheduleSms(item, content) {
      let result = "confirm";

      if (content == null) {
        const confirmResult = await core.alert.show({
          title: "확인",
          body: `현재 <span class="blue--text">일정</span> 정보를 전송하시겠습니까?`,
          showCancelButton: true,
          cancelButtonText: "아니오",
          confirmButtonText: "예",
        });
        result = String(confirmResult);
      }

      if (result === "confirm") {
        try {
          core.loader.show("문자 전송중...");
          let smsPushId = "";

          if (content == null) {
            smsPushId = (await EstimateService.sendSms(item.id, {
              type: "SCHEDULE",
            })) as string;
          } else {
            smsPushId = (await EstimateService.sendSms(item.id, {
              type: "CONTENT",
              content: content,
            })) as string;
          }
          const estimate = this.estimate;
          const interval = setInterval(async () => {
            try {
              const apiResult = (await SmsService.getSmsResult(smsPushId)) as any;
              if (apiResult.result) {
                clearInterval(interval);
                core.loader.hide();
                core.alert.show({
                  title: "알림",
                  body: "문자 전송 완료",
                });
                // 문자 전송 시간 갱신
                try {
                  const tableResult = (await EstimateService.getTable({
                    draw: 0,
                    start: 0,
                    length: 10,
                    orderColumnName: "id",
                    order: TableOrder.DESC,
                    searchColumns: { id: item.id },
                  })) as any;
                  if (tableResult.data.length > 0) {
                    const newItem = tableResult.data[0];
                    for (const key of Object.keys(newItem)) {
                      estimate[key] = newItem[key];
                    }
                  } else {
                    console.log("Unknown TableData");
                  }
                } catch (e) {
                  console.log(e);
                }
              } else if (apiResult.code < 0) {
                clearInterval(interval);
                core.loader.hide();
                core.alert.show({
                  title: "알림",
                  body: apiResult.message,
                });
              }
            } catch (e) {
              console.log(e);
            }
          }, 2000);
        } catch (e) {
          console.log(e);
        }
      }
    },

    sendPaymentSms() {
      const modal = this.modal.paymentSms;
      modal.params.estimate = this.estimate;
      modal.visible = true;
    },
  },
});
