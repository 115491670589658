import core from "@/core";
import { TableRequest } from "@/types";

class EstimateService {
  get(id: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/work/estimate/${id}`, null)
        .then((data: any) => {
          resolve(data.estimate);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getTable(params: TableRequest) {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/work/estimate/table`, null, params)
        .then((data: any) => {
          resolve(data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  create(params: any) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify(params);
      core.http
        .postJson(`/api/work/estimate`, strParams)
        .then((data: any) => {
          if (data.estimate != null) {
            resolve(data.estimate);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  createTemporary(params: any) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify(params);
      core.http
        .postJson(`/api/work/estimate/temporary`, strParams)
        .then((data: any) => {
          if (data.estimate != null) {
            resolve(data.estimate);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  update(id: number, params: any) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify(params);
      core.http
        .putJson(`/api/work/estimate/${id}`, strParams)
        .then((data: any) => {
          if (data.estimate != null) {
            resolve(data.estimate);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  restore(id: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .putJson(`/api/work/estimate/${id}/restore`, "")
        .then((data: any) => {
          if (data.estimate != null) {
            resolve(data.estimate);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  sendSms(estimateId: number, params: any) {
    // type:ESTIMATE, SCHEDULE, CONTENT
    const strParam = JSON.stringify(params);
    return new Promise((resolve: any, reject) => {
      core.http
        .postJson(`/api/work/estimate/${estimateId}/sms`, strParam)
        .then((data: any) => {
          if (data.smsPushId != null) {
            resolve(data.smsPushId);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getSmsSchedule(estimateId: number) {
    // type:ESTIMATE, SCHEDULE, CONTENT
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/work/estimate/${estimateId}/sms/schedule`, null, null)
        .then((data: any) => {
          if (data.content != null) {
            resolve(data.content);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  sendReviewSms(estimateId: number) {
    // type:ESTIMATE, SCHEDULE, CONTENT
    return new Promise((resolve: any, reject) => {
      core.http
        .postJson(`/api/work/estimate/${estimateId}/review-sms`, "")
        .then((data: any) => {
          if (data.smsPushId != null) {
            resolve(data.smsPushId);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  delete(estimateId: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .delete(`/api/work/estimate/${estimateId}`)
        .then((data: any) => {
          resolve(data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getPaymentList(estimateId: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/work/estimate/${estimateId}/payment`, null)
        .then((data: any) => {
          resolve(data.paymentList);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  createPayment(estimateId: number, params: any) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify(params);
      core.http
        .postJson(`/api/work/estimate/${estimateId}/payment`, strParams)
        .then((data: any) => {
          if (data.payment != null) {
            resolve(data.payment);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  updatePayment(estimateId: number, paymentId: number, params: any) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify(params);
      core.http
        .putJson(`/api/work/estimate/${estimateId}/payment/${paymentId}`, strParams)
        .then((data: any) => {
          if (data.payment != null) {
            resolve(data.payment);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  deletePayment(estimateId: number, paymentId: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .delete(`/api/work/estimate/${estimateId}/payment/${paymentId}`)
        .then((data: any) => {
          resolve(data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  updateContract(estimateId: number, status: string) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify({ contractStatus: status });
      core.http
        .putJson(`/api/work/estimate/${estimateId}/contract`, strParams)
        .then((data: any) => {
          if (data.estimate != null) {
            resolve(data.estimate);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  updateSchedule(estimateId: number, yn: string) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify({ scheduleYn: yn });
      core.http
        .putJson(`/api/work/estimate/${estimateId}/schedule`, strParams)
        .then((data: any) => {
          if (data.estimate != null) {
            resolve(data.estimate);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  existsEstimate(params: any) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify(params);
      core.http
        .postJson(`/api/work/estimate/exists`, strParams)
        .then((data: any) => {
          resolve(data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getScheduleList(id: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/work/estimate/${id}/schedule`, null)
        .then((data: any) => {
          resolve(data.scheduleList);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  updateMemo(estimateId: any, memo: any) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify({ memo: memo });
      core.http
        .putJson(`/api/work/estimate/${estimateId}/memo`, strParams)
        .then((data: any) => {
          if (data.estimate != null) {
            resolve(data.estimate);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  updateExpectedDate(estimateId: any, expectedDate: any) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify({ expectedDate: expectedDate });
      core.http
        .putJson(`/api/work/estimate/${estimateId}/expected-date`, strParams)
        .then((data: any) => {
          if (data.estimate != null) {
            resolve(data.estimate);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  updateLastPaymentSms(estimateId: any, smsId: any) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify({ smsId: smsId });
      core.http
        .postJson(`/api/work/estimate/${estimateId}/last-payment-sms`, strParams)
        .then((data: any) => {
          resolve(data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  updateIssue(estimateId: any, issue: boolean) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify({ issue: issue });
      core.http
        .putJson(`/api/work/estimate/${estimateId}/issue`, strParams)
        .then((data: any) => {
          resolve(data.estimate);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  updateCaution(estimateId: any, caution: boolean) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify({ caution: caution });
      core.http
        .putJson(`/api/work/estimate/${estimateId}/caution`, strParams)
        .then((data: any) => {
          resolve(data.estimate);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  updateAutoPaymentSms(estimateId: any, autoPaymentSms: boolean) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify({ autoPaymentSms: autoPaymentSms });
      core.http
        .putJson(`/api/work/estimate/${estimateId}/auto-payment-sms`, strParams)
        .then((data: any) => {
          resolve(data.estimate);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  updateCategoryTeam(estimateId: any, params: any) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify(params);
      core.http
        .putJson(`/api/work/estimate/${estimateId}/category`, strParams)
        .then((data: any) => {
          resolve(data.estimate);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  downloadExcel(params: any) {
    return new Promise((resolve: any, reject) => {
      core.http
        .get(`/api/work/estimate/excel`, null, params)
        .then((data: any) => {
          resolve(data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  changePlace(srcPlaceId, destPlaceId) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify({ srcPlaceId: srcPlaceId, destPlaceId: destPlaceId });
      core.http
        .putJson(`/api/work/estimate/place`, strParams)
        .then((data: any) => {
          resolve(data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getMemoList(estimateId: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/work/estimate/${estimateId}/memo`, null)
        .then((data: any) => {
          resolve(data.memoList);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  createMemoInfo(estimateId: number, params: any) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify(params);
      core.http
        .postJson(`/api/work/estimate/${estimateId}/memo`, strParams)
        .then((data: any) => {
          if (data.memo != null) {
            resolve(data.memo);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  updateMemoInfo(estimateId: number, memoId: number, params: any) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify(params);
      core.http
        .putJson(`/api/work/estimate/${estimateId}/memo/${memoId}`, strParams)
        .then((data: any) => {
          if (data.memo != null) {
            resolve(data.memo);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  deleteMemoInfo(estimateId: number, memoId: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .delete(`/api/work/estimate/${estimateId}/memo/${memoId}`)
        .then((data: any) => {
          resolve(data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  createCounsel(params: any) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify(params);
      core.http
        .postJson(`/api/work/estimate/counsel`, strParams)
        .then((data: any) => {
          if (data.counselEstimate != null) {
            resolve(data.counselEstimate);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  updateCounsel(id: number, params: any) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify(params);
      core.http
        .putJson(`/api/work/estimate/counsel/${id}`, strParams)
        .then((data: any) => {
          if (data.counselEstimate != null) {
            resolve(data.counselEstimate);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getCounselTable(params: TableRequest) {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/work/estimate/counsel/table`, null, params)
        .then((data: any) => {
          resolve(data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getDetailVersion(estimateId: number) {
    return new Promise((resolve: any, reject) => {
      core.http
        .getJson(`/api/work/estimate/${estimateId}/detail-version`, null, null)
        .then((data: any) => {
          if (data.detailVersion) {
            resolve(data.detailVersion);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }
}

export default new EstimateService();
